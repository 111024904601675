<div role="group" class="tel-input-container" [formGroup]="parts">
	<input
		class="tel-input-element"
		formControlName="area"
		size="3"
		maxLength="3"
		(keyup)="_handleInput(parts.controls.area, exchange)"
		#area
	/>
	<span class="tel-input-spacer">&ndash;</span>
	<input
		class="tel-input-element"
		formControlName="exchange"
		maxLength="3"
		size="3"
		(keyup)="_handleInput(parts.controls.exchange, subscriber)"
		(keyup.backspace)="autoFocusPrev(parts.controls.exchange, area)"
		#exchange
	/>
	<span class="tel-input-spacer">&ndash;</span>
	<input
		class="tel-input-element"
		formControlName="subscriber"
		maxLength="4"
		size="4"
		(keyup)="_handleInput(parts.controls.subscriber)"
		(keyup.backspace)="autoFocusPrev(parts.controls.subscriber, exchange)"
		#subscriber
	/>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { COMPONENTS } from './components/components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';

@NgModule({
	imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
	declarations: [...COMPONENTS],
	exports: [...COMPONENTS],
})
export class SharedModule {}

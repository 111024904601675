<p class="c2"><span class="c3 c8">CONSIDERACIONES</span></p>
<p class="c2">
	<span class="c3">(a). </span
	><span class="c0"
		>&ldquo;EL OPERADOR&rdquo; cuenta con una plataforma virtual donde se
		exhiben productos de consumo y donde permite el acceso a Consumidores
		para su conocimiento y solicitud de encargos por éstos. </span
	><span class="c3">(b).</span
	><span class="c0">
		La exhibición de los productos de consumo sirve como referencia a los
		Consumidores para su compra y recepción por medio de un sistema de
		domicilios. </span
	><span class="c3">(c).</span
	><span class="c0">
		En la plataforma se vinculan terceros en calidad de Mandatarios, quienes
		gestionan encargos solicitados por los usuarios para que éstos últimos
		adquieran los productos de consumo que exhibe EL OPERADOR. </span
	><span class="c3">(d).</span
	><span class="c0">
		La tecnología de la plataforma permite que los Consumidores al momento
		de requerir este tipo de productos, soliciten el encargo directamente a
		los Mandatarios, solicitud que se materializa con la celebración de un
		contrato de mandato directamente entre Consumidores y Mandatarios, cuyo
		encargo consiste en la celebración de un contrato de compraventa o
		cualquier otro tipo de contrato lícito, con el fin de adquirir bienes o
		servicios. </span
	><span class="c3">(e). Mandatarios:</span
	><span class="c0">
		Persona natural que acepta realizar la gestión del encargo solicitado
		por el Consumidor a través de la Plataforma. Para este efecto se
		encuentra autorizado al uso de la plataforma del OPERADOR, para la
		vinculación directa con los consumidores para desarrollar la gestión de
		encargos. </span
	><span class="c3">(f).</span
	><span class="c1 c0">
		Por estas razones, con la aceptación de estos términos y condiciones de
		uso de plataforma, &ldquo;El OPERADOR&rdquo; otorga autorización de uso
		al &ldquo;Mandatario&rdquo;.</span
	>
</p>
<p class="c2">
	<span class="c3">DEFINICIONES:</span
	><span class="c1 c0">
		Para los efectos de estos términos y condiciones se entiende por:
	</span>
</p>
<p class="c4">
	<span class="c0">1. </span><span class="c3">Comercio Electrónico: </span
	><span class="c1 c0"
		>Comprende el envío, transmisión, recepción, almacenamiento de mensajes
		de datos por vía electrónica. Las dudas que surjan respecto de la
		eficacia y validez de los mensajes de datos y demás actividades
		vinculadas al comercio electrónico se interpretarán de conformidad con
		la Ley 527 de 1999.
	</span>
</p>
<p class="c4">
	<span class="c0">2. </span
	><span class="c3"
		>Contrato de mandato remunerado celebrado por medios electrónicos: </span
	><span class="c1 c0"
		>Aquel acuerdo de voluntades celebrado entre el Consumidor y el
		Mandatario, por medio del cual el Consumidor solicita, a través de la
		Plataforma, la gestión de un encargo al mandatario, obligándose este
		último a cumplir con dicho encargo por cuenta y riesgo del Consumidor, a
		cambio de recibir una remuneración como contraprestación.
	</span>
</p>
<p class="c4">
	<span class="c0">3. </span><span class="c3">Consumidores: </span
	><span class="c1 c0"
		>Toda persona natural que, como destinatario final, use La Plataforma
		para solicitar por medio de ésta un mandato remunerado, cuyo encargo
		consiste en la celebración de un contrato de compraventa o cualquier
		otro tipo de contrato lícito, con el fin de adquirir bienes o servicios.
	</span>
</p>
<p class="c4">
	<span class="c0">4.</span><span class="c3"> Datos personales: </span
	><span class="c1 c0"
		>Es toda información que permite identificar o hacer identificable a una
		persona física.
	</span>
</p>
<p class="c4">
	<span class="c0">5. </span><span class="c3">Mensajes de datos: </span
	><span class="c1 c0"
		>La información generada, enviada, recibida, almacenada o comunicada por
		medios electrónicos, ópticos o similares, como pudieran ser, entre
		otros, el Intercambio Electrónico de Datos (EDI), Internet, el correo
		electrónico, el telegrama, el télex o el telefax.
	</span>
</p>
<p class="c4">
	<span class="c0">6. </span><span class="c3">Pasarela de pagos: </span
	><span class="c1 c0"
		>Servicio utilizado por EL OPERADOR en la operación comercial de la
		Plataforma que permite la realización de pagos por parte de los
		Consumidores directamente a los Mandatarios a través de plataformas
		tecnológicas diseñadas para ello (software), específicamente la
		denominada PayU, plataforma que permite el pago directo por medio de la
		recepción y dispersión del dinero a los Mandatarios.
	</span>
</p>
<p class="c4">
	<span class="c0">7. </span><span class="c3">Plataforma</span
	><span class="c3 c8">.</span
	><span class="c0">
		Aplicativo web y móvil administrado por el OPERADOR, que permite la
		concurrencia de Consumidores y Mandatarios para que por medio de
		contratos de mandato el Consumidor solicite la gestión de un encargo.
		Existe esta Plataforma, </span
	><span class="c3">TRANSANPABLO EXPRESS ENTREGA</span
	><span class="c0">
		a la cual acceden los Mandatarios y la cual se rige por los términos y
		condiciones aquí descritos. Por otro lado, existe la Plataforma </span
	><span class="c3">TRANSANPABLO EXPRESS </span
	><span class="c1 c0">a la cual acceden los Consumidores. </span>
</p>
<p class="c4">
	<span class="c0">8. </span><span class="c3">Productos y servicios: </span
	><span class="c1 c0"
		>Bienes y servicios de consumo exhibidos a través de la Plataforma por
		EL OPERADOR, cuya adquisición es encargada por los Consumidores, por
		medio de un contrato de mandato remunerado, a los Mandatarios.
	</span>
</p>
<p class="c4">
	<span class="c0">9. </span
	><span class="c3">Ventanas emergentes (Pop-Ups):</span
	><span class="c1 c0">
		Ventana o aviso de internet que emerge automáticamente en cualquier
		momento para quienes usan la Plataforma.</span
	>
</p>
<p class="c4 c7"><span class="c1 c0"></span></p>
<p class="c2">
	<span class="c3">AUTORIZACIóN.</span
	><span class="c0">
		Mediante la aceptación de estos términos y condiciones se constituye la
		autorización de uso de la Plataforma </span
	><span class="c3">TRANSANPABLO EXPRESS ENTREGA</span
	><span class="c0"
		>, que otorga de forma gratuita EL OPERADOR en favor del MANDATARIO,
		para que éste concurra a un mercado virtual con la finalidad de
		identificarse en éste como Mandatario, y para realizar la gestión del
		encargo solicitado por el Consumidor a través de la Plataforma </span
	><span class="c3">TRANSANPABLO EXPRESS</span><span class="c1 c0">.</span>
</p>
<p class="c2">
	<span class="c3">Parágrafo: </span
	><span class="c0"
		>Los menores de edad (menores de 18 años) no podrán, bajo ningún
		supuesto, ser autorizados al uso de esta plataforma, </span
	><span class="c3">TRANSANPABLO EXPRESS ENTREGA</span
	><span class="c1 c0"
		>, y por lo tanto no podrán activarse en calidad de Mandatarios.</span
	>
</p>
<p class="c2">
	<span class="c3">ALCANCE</span
	><span class="c0">. El MANDATARIO utilizará la Plataforma </span
	><span class="c3">TRANSANPABLO EXPRESS ENTREGA</span
	><span class="c0">
		para vincularse contractualmente de forma directa con los Consumidores.
		Para este efecto, EL OPERADOR: </span
	><span class="c3 c8">i)</span
	><span class="c0">
		comunicará a los Consumidores información cierta, fidedigna, suficiente,
		clara y actualizada respecto de los productos que exhibe; </span
	><span class="c3 c8">ii)</span
	><span class="c1 c0">
		comunicará a los Consumidores la información detallada del MANDATARIO
		como prestador del servicio;</span
	>
</p>
<p class="c2">
	<span class="c3">DESCRIPCIóN DEL SERVICIO. </span
	><span class="c0"
		>El MANDATARIO declara conocer y aceptar los términos y condiciones de
		uso a los cuales se encuentran sujetos los Consumidores para usar la
		plataforma </span
	><span class="c3">TRANSANPABLO EXPRESS ENTREGA</span
	><span class="c0"
		>. El servicio opera como tienda virtual, en la que los Consumidores
		ingresan a la Plataforma para solicitar un mandato remunerado, cuyo
		encargo consiste en la celebración de un contrato de compraventa o
		cualquier otro tipo de contrato lícito, con el fin de adquirir bienes o
		servicios. Una vez se solicita el encargo, la Plataforma </span
	><span class="c3">TRANSANPABLO EXPRESS ENTREGA</span
	><span class="c1 c0">
		exhibe los encargos de los Consumidores a los Mandatarios y ellos
		seleccionan los encargos para gestionarlos, a manera de aceptación del
		contrato de mandato remunerado. La solicitud de encargo y selección
		depende del ámbito territorial de injerencia del Mandatario y de la
		capacidad de entrega de los productos requeridos por el Consumidor. Al
		seleccionar el pedido, el Mandatario es notificado por el Consumidor
		respecto de los productos y/o servicios solicitados, así como de las
		condiciones de tiempo, modo y lugar de entrega, las cuales son de
		obligatorio cumplimiento por el MANDATARIO frente al Consumidor.</span
	>
</p>
<p class="c2">
	<span class="c0"
		>Al momento de la notificación del servicio, el MANDATARIO podrá
		declinar la solicitud de encargo (rechazar el mandato), de forma
		unilateral sea por la imposibilidad de conseguir los productos o por
		condiciones de tiempo y ubicación. En caso de rechazo, la plataforma </span
	><span class="c3">TRANSANPABLO EXPRESS ENTREGA</span
	><span class="c1 c0">
		posicionará el encargo nuevamente, a fin de que los demás Mandatarios
		habilitados en la zona puedan tomarlo y satisfacer las necesidades del
		Consumidor.</span
	>
</p>
<p class="c2">
	<span class="c3">Parágrafo.</span
	><span class="c1 c0">
		Entiende y acepta el MANDATARIO que EL OPERADOR podrá modificar
		unilateralmente en cualquier momento y sentido los presentes Términos y
		Condiciones.</span
	>
</p>
<p class="c2">
	<span class="c3">DURACIóN.</span
	><span class="c1 c0">
		La presente autorización de uso es indefinida, no obstante, EL OPERADOR
		podrá unilateralmente revocar la autorización de uso en cualquier
		momento si así lo desea.</span
	>
</p>
<p class="c2">
	<span class="c3">DEBERES DEL MANDATARIO. </span
	><span class="c1 c0"
		>En virtud de estos términos y condiciones el MANDATARIO se compromete
		a:
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>1) Usar la plataforma única y exclusivamente en los términos definidos
		aquí;
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>2) Suministrar información real y fidedigna al momento de llenar la
		suscripción de acceso a la Plataforma;
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>3) Entregar al Consumidor oportunamente y en los términos definidos en
		la transacción;
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>4) Informar al OPERADOR sobre cualquier aspecto que modifique las
		condiciones de prestación de servicios a los Consumidores, como
		territorio en el que puede realizar los domicilios, tiempo de entrega,
		calidad de los productos, entre otros;
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>5) Respetar todos los elementos de propiedad intelectual del OPERADOR,
		por lo cual se abstendrá de realizar ingeniería inversa, descompilación,
		derivación de código fuente o similares;
	</span>
</p>
<p class="c4">
	<span class="c0"
		>6) Deberá abstenerse de consumir bebidas embriagantes, alucinógenos,
		narcóticos, y demás sustancias que puedan afectar su estado psicológico
		normal. Para ello el OPERADOR está facultado para realizar exámenes u
		otras pruebas destinadas a comprobar esta situación. En caso de
		comprobarse el consumo habitual de substancias prohibidas por la ley o
		bebidas alcohólicas durante el uso de la plataforma será considerado
		como causal de violación de estos términos y condiciones y se denegará
		el acceso a la Plataforma </span
	><span class="c3">TRANSANPABLO EXPRESS ENTREGA</span
	><span class="c1 c0"> al Mandatario involucrado. </span>
</p>
<p class="c4">
	<span class="c1 c0"
		>7) Mantener una calificación promedio mensual superior al 4.0 sobre 5,
		la cual será calculada sobre las calificaciones entregadas por los
		CONSUMIDORES al finalizar cada pedido. Dicha calificación será realizada
		por el usuario a través del uso de estrellas, donde cada estrella
		equivale a un (1) punto, siendo un (1) punto la menor calificación y
		cinco (5) puntos la mayor calificación que pueda obtener el Mandatario
		en su servicio.
	</span>
</p>
<p class="c4">
	<span class="c0"
		>8) El MANDATARIO se obliga a estar afiliado al sistema de seguridad
		social, y a mantener dicha afiliación vigente durante la duración de la
		autorización del uso de la Plataforma </span
	><span class="c3">TRANSANPABLO EXPRESS ENTREGA</span
	><span class="c1 c0">, según lo dispone la ley colombiana. </span>
</p>
<p class="c4">
	<span class="c1 c0"
		>9) A proveer un documento de identidad nacional (Cédula de ciudadanía)
		original, expedido por la Registraduría General del Estado Civil
		colombiano (o quien haga sus veces en caso de cédula de extranjería), a
		cargar dos fotos del documento en la aplicación, una por cada lado, e
		igualmente a proveer un documento soporte que contenga el mismo número
		que aparece en su cédula.</span
	>
</p>
<p class="c4">
	<span class="c0"
		>10) A tener en cuenta las recomendaciones de buen servicio del
		Mandatario consagradas en el archivo ANEXO que se envía posterior a la
		aceptación de estos términos y condiciones y a la correspondiente
		activación dentro de la plataforma </span
	><span class="c3">TRANSANPABLO EXPRESS ENTREGA</span
	><span class="c1 c0"> en calidad de Mandatario. </span>
</p>
<p class="c4">
	<span class="c1 c0"
		>11) El Mandatario tiene la obligación de exigir la cédula de ciudadanía
		original o documento de identidad al Usuario Consumidor cuando la orden
		que entregue incluya productos de bebidas alcohólicas. En caso de que el
		Usuario Consumidor no presente su documento de identidad al Mandatario,
		éste está obligado a no entregarle dichos productos.
	</span>
</p>
<p class="c4 c7"><span class="c1 c0"></span></p>
<p class="c2">
	<span class="c3">PRODUCTOS EXHIBIDOS. </span
	><span class="c1 c0"
		>EL OPERADOR en la Plataforma exhibe múltiples productos y/o servicios
		de múltiples referencias y marcas, dicha exhibición informa de forma
		general las características de los productos como marca, tipo de
		producto y presentación. -Los Consumidores seleccionan los productos que
		desean adquirir con base en esta información, utilizándola como
		referencia. Por esta razón, el MANDATARIO debe propender por entregar a
		los Consumidores productos idénticos a los seleccionados para la compra
		por parte de los Consumidores. En caso de no poder entregar productos
		idénticos a los seleccionados por los Consumidores, deberá comunicarse
		con el Consumidor a fin de proponerle alternativas de productos o
		servicios, sujetas a la aprobación de este último. El Mandatario podrá,
		en todo caso, declinar, abstenerse de aceptar el encargo solicitado o
		informar al Consumidor la posibilidad de entrega de un producto
		sustituto.</span
	>
</p>
<p class="c2">
	<span class="c3">CUMPLIMIENTO. </span
	><span class="c1 c0"
		>Entiende, conoce y acepta el MANDATARIO que la relación contractual que
		lo vincula con el Consumidor es una relación jurídica de mandato, por
		esta razón se obliga a cumplir con las obligaciones prescritas en la
		normatividad para este tipo de relación contractual. En la prestación
		del servicio, el MANDATARIO recibirá una calificación por parte de los
		Consumidores, explicada en líneas anteriores. Será causal de revocación
		automática de la autorización de uso de la Plataforma, la calificación
		negativa (calificación de 1 y 2) que reciba el MANDATARIO por tres (3)
		ocasiones en un mes. El MANDATARIO reconoce y acepta que realiza el
		encargo a favor del Consumidor por cuenta y riesgo propio, liberándolo
		de cualquier tipo de responsabilidad por hechos que ocurran durante la
		ejecución del encargo.</span
	>
</p>
<p class="c2">
	<span class="c3">ORIGEN DE LOS PRODUCTOS.</span
	><span class="c1 c0">
		El MANDATARIO garantiza al OPERADOR y a los Consumidores que el origen
		de los productos que se obliga a entregar es legal, estos deben ser
		productos legítimamente adquiridos de proveedores certificados y
		reconocidos por las marcas de los productos, por lo tanto, se abstendrá
		de adquirir para la entrega, imitaciones, plagios, contrabando y demás
		productos que no cumplan con las especificaciones definidas en los
		presentes términos y condiciones, o que sean productos al margen de la
		ley y no comercializables.</span
	>
</p>
<p class="c2">
	<span class="c3">PAGOS. </span
	><span class="c0">
		Por medio de estos términos y condiciones el MANDATARIO subroga al
		OPERADOR en sus derechos como acreedor frente al Consumidor. EL OPERADOR
		dentro de la Plataforma tiene implementado, además del método de pago en
		efectivo contra entrega, un aplicativo transaccional que permite que los
		Consumidores paguen los productos adquiridos y la remuneración del
		mandato. Una vez el dinero ingrese a la pasarela de pagos, se enviará la
		remuneración del mandato a la cuenta bancaria del Mandatario. Para ello,
		el OPERADOR inscribirá en el aplicativo la cuenta bancaria que se
		encuentra identificada en la información que el Mandatario provea para
		su inscripción en la Plataforma </span
	><span class="c3">TRANSANPABLO EXPRESS ENTREGA</span
	><span class="c1 c0">.</span>
</p>
<p class="c2">
	<span class="c3">FACTURACIóN. </span
	><span class="c0"
		>El MANDATARIO, en caso de que el Consumidor lo solicite, deberá,
		entregarle al usuario la factura del establecimiento donde adquirió los
		productos. El Mandatario deberá informar al Consumidor que, al correo
		electrónico, registrado en la Plataforma </span
	><span class="c3">TRANSANPABLO EXPRESS</span
	><span class="c1 c0"
		>, deberá llegar un comprobante de compra donde se detallarán los
		números de la transacción efectuada.</span
	>
</p>
<p class="c2">
	<span class="c3">RELACIóN JURíDICA CON EL CONSUMIDOR. </span
	><span class="c1 c0"
		>El MANDATARIO se obliga a usar la Plataforma en las condiciones
		definidas en estos términos y condiciones, los cuales permiten la
		configuración de una relación jurídica entre el MANDATARIO y los
		Consumidores por la celebración de contratos de mandato remunerado, cuyo
		encargo consiste en la celebración de un contrato de compraventa o
		cualquier otro tipo de contrato lícito, con el fin de adquirir bienes
		y/o servicios. En este sentido, todos los efectos legales relacionados
		con cumplimiento y responsabilidad establecidos para este tipo de
		relación recaen sobre el MANDATARIO.</span
	>
</p>
<p class="c2">
	<span class="c3">CANCELACIóN DEL ACCESO A LA PLATAFORMA.</span
	><span class="c1 c0">
		El Mandatario no podrá acceder a la Plataforma por las siguientes
		causas:
	</span>
</p>
<p class="c4">
	<span class="c1 c0">a) Por decisión unilateral del OPERADOR; </span>
</p>
<p class="c4">
	<span class="c1 c0"
		>b) Por realizar acciones delictivas o cualquier otra que contravenga
		las normas y buenas costumbres en detrimento de la plataforma y/o
		aprovechándose de esta o en detrimento de cualquier otro tercero que
		resulte afectado por cualquier tipo de conducta delictiva por parte del
		Mandatario;
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>c) Por encontrarse inmerso en un proceso penal y/o tener antecedentes
		penales;
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>d) Por afectar el patrimonio y la buena fe de TRANSANPABLO EXPRESS SAS
		y/o de terceros;</span
	>
</p>
<p class="c4">
	<span class="c1 c0"
		>e) Por usar las marcas, logotipos, nombres e insignias propiedad de
		TRANSANPABLO EXPRESS SAS sin autorización previa y por escrito de ésta;
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>f) Por usar y aprovecharse del uso de la plataforma virtual para hacer
		publicidad y promover empresas ajenas a TRANSANPABLO EXPRESS SAS, sin la
		previa autorización expresa y escrita de esta;</span
	>
</p>
<p class="c4">
	<span class="c1 c0"
		>g) Por usar la aplicación para financiar el terrorismo y/o cualquier
		otro tipo de organización o actividad ilegal;</span
	>
</p>
<p class="c4">
	<span class="c1 c0"
		>h) Por usar la aplicación para el lavado de activos;
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>i) Por proveer información falsa de afiliaciones al sistema de Salud y
		pensiones;
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>j) Por encontrarse desafiliado al sistema de salud y pensiones
		posterior a haberse otorgado la autorización al uso de la
		plataforma;</span
	>
</p>
<p class="c4">
	<span class="c0"
		>k) Por tener más de una (1) cuenta creada entre la aplicación </span
	><span class="c3">TRANSANPABLO EXPRESS </span
	><span class="c0">y la de </span
	><span class="c3">TRANSANPABLO EXPRESS ENTREGA</span
	><span class="c0 c1">
		con el mismo número de identidad, veraz, auténtico y expedido por la
		Registraduría Nacional del Estado Civil o por prestarse para
		sustitución/suplantación de identidad, conducta penalizada por el Código
		Penal colombiano en su artículo 296. En caso de que ocurra esta última
		causal, el titular de la cuenta será co-responsable de cualquier tipo de
		conducta delictiva que se realice con su cuenta;</span
	>
</p>
<p class="c4">
	<span class="c1 c0"
		>l) Por usar los elementos que le permiten prestar servicios a los
		Consumidores para fines ilícitos o que contravengan las buenas
		costumbres laborales y mercantiles.</span
	>
</p>
<p class="c4">
	<span class="c1 c0"
		>m) Por cualquier otro tipo de causal consagrada en la normatividad
		colombiana que amerite la cancelación de la autorización al uso de la
		plataforma.</span
	>
</p>
<p class="c4 c7"><span class="c1 c0"></span></p>
<p class="c2">
	<span class="c3">AUTONOMíA.</span
	><span class="c1 c0">
		Estos términos y condiciones se rigen por la ley mercantil colombiana, y
		por su naturaleza jurídica no genera relación laboral entre las partes,
		ni tampoco genera relación de representación, de mandato, de agencia, de
		corretaje o de comisión entre el OPERADOR y el MANDATARIO. Estos
		términos y condiciones dejan sin vigencia cualquier otro tipo de acuerdo
		o autorización que existiera entre las partes anteriormente sobre el
		mismo o similar objeto, pues las relaciones entre ellas se regirán
		exclusivamente por estos términos y condiciones en lo sucesivo.</span
	>
</p>
<p class="c2">
	<span class="c3"
		>USO DE MARCAS, LOGOS, NOMBRE E INSIGNIAS DE TRANSANPABLO EXPRESS SAS. </span
	><span class="c1 c0"
		>El Mandatario reconoce que no podrá usar, reproducir, modificar,
		explotar, publicitar o realizar cualquier otra acción con las marcas,
		logotipos, nombres e insignias de TRANSANPABLO EXPRESS SAS sin la previa
		autorización expresa y por escrito de TRANSANPABLO EXPRESS SAS. El
		Mandatario acepta que el incumplimiento de lo anterior acarreará las
		sanciones civiles y penales que correspondan e igualmente la inmediata
		desactivación para el uso de la plataforma.</span
	>
</p>
<p class="c2">
	<span class="c3">ORIGEN DE INGRESOS. </span
	><span class="c1 c0"
		>El Mandatario declara, bajo la gravedad del juramento, que sus ingresos
		provienen de actividades lícitas, que no se encuentra con registro
		negativo en listados de prevención de lavado de activos o financiación
		del terrorismo nacionales o internacionales, ni dentro de una de las dos
		categorías de lavado de activos (conversión o movimiento) y, en
		consecuencia, se obliga a responder frente a TRANSANPABLO EXPRESS SAS y
		terceros afectados por todos los perjuicios que se llegaren a causar
		como consecuencia de la falsedad de esta afirmación.</span
	>
</p>
<p class="c2">
	<span class="c1 c0"
		>Igualmente, el Mandatario declara que, el respeto por el buen nombre y
		la reputación de TRANSANPABLO EXPRESS SAS son elementos claves para
		continuar con su autorización al uso de la plataforma, razón por la
		cual, en caso de estar involucrado en lavado de activos, tráfico de
		estupefacientes, trata de personas, proxenetismo, pornografía infantil,
		peculado, concusión, secuestro, extorsión, corrupción, tráfico de
		influencias, contrabando, pertenencia o tratos con organizaciones
		armadas ilegales o con organizaciones criminales de cualquier índole,
		tráfico de armas, terrorismo, delitos de lesa humanidad o delitos contra
		la libertad, integridad y formación sexuales, etc, será motivo
		suficiente para revocar la autorización al uso de la plataforma y libera
		a TRANSANPABLO EXPRESS SAS de cualquier responsabilidad que pueda
		generarse con motivo de las actividades descritas anteriormente.</span
	>
</p>
<p class="c2">
	<span class="c1 c0"
		>Para el efecto, el Mandatario se compromete a cumplir con los
		requisitos y obligaciones que dentro de la normatividad legal vigente le
		apliquen, y en especial en lo relativo a la prevención del riesgo de
		lavado de activos y financiación del terrorismo, dentro de los que se
		encuentran, entre otras, el entregar información veraz y verificable y
		el actualizar su información personal, comercial y financiera por lo
		menos una vez al año, o cada vez que así lo solicite TRANSANPABLO
		EXPRESS SAS, suministrando la totalidad de los soportes documentales
		exigidos, so pena de ver revocada su autorización al uso de la
		plataforma.</span
	>
</p>
<p class="c2">
	<span class="c3">HABEAS DATA.</span
	><span class="c1 c0">
		Manifiesta expresamente el MANDATARIO que ha sido informado respecto a
		que la información personal, por él suministrada y, de la que tenga
		conocimiento EL OPERADOR, en virtud de estos términos y condiciones,
		tales como: Nombre, Apellido, E-mail, Teléfono, Dirección, Documento de
		identidad, Fotografías y demás necesarios para su inscripción y la
		prestación del servicio a los consumidores; la cual podrá ser almacenada
		por EL OPERADOR con la finalidad de ser comunicados a los Consumidores
		para la prestación adecuada del servicio, y para tratar aquellos que
		resulten pertinentes para comunicar al MANDATARIO información comercial,
		publicitaria y de mercadeo relacionada con su actividad comercial; así
		mismo, para ser objeto de análisis para fines de mejorar la estrategia
		de negocios del portal web, apoyada en herramientas de inteligencia de
		negocios y minería de datos, que permiten adquirir conocimientos
		prospectivos para fines de predicción, clasificación y segmentación;
		además del tratamiento que resulte necesario para el cumplimiento de los
		deberes adquiridos en virtud de estos términos y condiciones.
		Finalmente, el Mandatario acepta que TRANSANPABLO EXPRESS SAS transfiera
		sus datos personales en caso de que un Consumidor los solicite mediante
		un derecho de petición.</span
	>
</p>
<p class="c2">
	<span class="c1 c0"
		>Declara expresamente el MANDATARIO que no utilizará para provecho
		propio o de terceros información relacionada con clientes, servicios,
		productos y demás, a la que tenga acceso en virtud de la presente
		autorización, razón por la cual se obliga a no almacenar ni tratar los
		datos personales de los Consumidores, ni a contactarlos después de haber
		entregado, declinado o cancelado cualquier pedido. El mal uso de estos
		datos personales por parte del Mandatario conllevará la automática
		suspensión y eliminación de su cuenta.</span
	>
</p>
<p class="c2">
	<span class="c1 c0"
		>El Mandatario reconoce y acepta que autoriza a TRANSANPABLO EXPRESS SAS
		y sus afiliadas, para transferir sus datos personales en caso de
		requerimiento oficial realizado por autoridad competente y/o judicial
		y/o administrativa.</span
	>
</p>
<p class="c2">
	<span class="c0"
		>El Mandatario reconoce y acepte que, en el caso de la Aplicación </span
	><span class="c3">TRANSANPABLO EXPRESS ENTREGA</span
	><span class="c1 c0"
		>, para que pueda aceptar y tramitar las órdenes más cercanas a su
		ubicación, la Aplicación recogerá datos de su localización, aún cuando
		la Aplicación se encuentre cerrada o no esté en uso.
	</span>
</p>
<p class="c2">
	<span class="c3">DECLARACIóN Y ACEPTACIóN. </span
	><span class="c0"
		>Así mismo, el Mandatario declara y acepta que a partir de la
		autorización que se le otorga con la presente aceptación de los términos
		y condiciones, no usará ni llevará a cabo, a través de la plataforma </span
	><span class="c3">TRANSANPABLO EXPRESS ENTREGA</span
	><span class="c1 c0">
		ni de cualquier otra de propiedad de TRANSANPABLO EXPRESS SAS y sus
		afiliadas o subsidiarias, ninguno tipo de acto delictivo o fraudulento
		que se encuentre sancionado y/o penado por la ley colombiana y cualquier
		tratado internacional aplicable firmado por el Estado colombiano, so
		pena de incurrir en investigaciones civiles o penales, que acarreen las
		correspondientes sanciones. Así mismo, el Mandatario autoriza a
		TRANSANPABLO EXPRESS SAS para que ésta investigue en cualquier momento
		su actividad dentro de la plataforma, solicitándole proveer cualquier
		tipo de información que sea requerida a fin de dar por superado el
		hecho.</span
	>
</p>
<p class="c2">
	<span class="c0"
		>En caso de que el Mandatario se encontrase involucrado en cualquier
		tipo de investigación dentro del marco de su actividad en la Plataforma </span
	><span class="c3">TRANSANPABLO EXPRESS ENTREGA</span
	><span class="c1 c0"
		>, TRANSANPABLO EXPRESS SAS podrá revocar la autorización de acceso a la
		misma, por el tiempo que sea necesario y no estará obligada a devolver
		dicha autorización, reservándose el derecho que le asiste como
		propietario de la misma de decidir el acceso de cualquier Mandatario a
		esta.</span
	>
</p>
<p class="c2">
	<span class="c3">TERRITORIO Y LEGISLACIóN APLICABLE</span
	><span class="c0"
		>. Los presentes términos y condiciones y sus anexos aplican al uso de
		la Plataforma </span
	><span class="c3">TRANSANPABLO EXPRESS ENTREGA</span
	><span class="c1 c0">
		en el territorio colombiano, conforme a su normatividad general y
		normatividad sectorial; que vinculan a las partes.</span
	>
</p>
<p class="c2">
	<span class="c0"
		>El Mandatario declara que este documento contiene la totalidad de los
		términos y condiciones que rigen su acceso a la Plataforma y que no
		existen acuerdos vigentes o pactos verbales ni de otra naturaleza sobre
		este asunto</span
	><span class="c3 c8">.</span>
</p>
<p class="c2">
	<span class="c3"
		>AVISO DE PRIVACIDAD Y POLíTICAS DE MANEJO DE LA INFORMACIóN (</span
	><span class="c3 c8">HABEAS DATA</span
	><span class="c3">) DE TRANSANPABLO EXPRESS ENTREGA APP</span>
</p>
<p class="c2">
	<span class="c0"
		>Para dar cumplimiento a lo dispuesto en la Ley Estatutaria 1581 de
		2012, reglamentada parcialmente por los Decretos 1377 de 2013 y Decreto
		886 de 2014, normas compiladas en el Decreto único Nacional del Sector
		Comercio, Industria y Turismo 1074 de 2015 (Artículos 2.2.2.25.1.1. al
		2.2.2.26.3.4), TRANSANPABLO EXPRESS SAS</span
	><span class="c3">,</span
	><span class="c0">
		en adelante TRANSANPABLO EXPRESS SAS le informa que sus datos serán
		tratados conforme a la </span
	><span class="c3">POLíTICA</span><span class="c0"> </span
	><span class="c3">DE TRATAMIENTO DE DATOS PERSONALES</span
	><span class="c1 c0">
		desarrollada por la empresa y publicada en la plataforma virtual,
		compuesta por una página web https://www.transanpablo.com y un
		aplicativo para dispositivos móviles. En este caso TRANSANPABLO EXPRESS
		SAS actúa como responsable del Tratamiento de los Datos Personales, por
		lo que mantendrá almacenados estos datos y guardará su confidencialidad
		atendiendo los requisitos y medidas de seguridad exigidos por la
		ley.</span
	>
</p>
<p class="c2">
	<span class="c0"
		>El tratamiento que le dará TRANSANPABLO EXPRESS SAS a los datos
		personales se limitará a la recolección, almacenamiento, uso,
		circulación al interior de la empresa, de su controlante, otras
		subordinadas o de sus proveedores, para las finalidades autorizadas por
		medio de las aplicaciones móviles, sitios web y otros productos y
		servicios online. Una vez se cumplan con las finalidades del tratamiento
		para la cual se otorgó autorización por parte del titular, los datos
		personales serán suprimidos de las bases de datos de TRANSANPABLO
		EXPRESS SAS</span
	><span class="c3">.</span>
</p>
<p class="c2">
	<span class="c3">Aviso de privacidad:</span
	><span class="c1 c0">
		El titular de la información acepta de manera expresa, voluntaria e
		informada que los datos consignados en las bases de datos de
		TRANSANPABLO EXPRESS SAS, sean recopilados, almacenados para los usos
		que a continuación se enuncian:
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>(i) Recabar o recolectar los datos personales e incorporarlos y
		almacenarlos en nuestra base de datos,
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>(ii) Ordenar, catalogar, clasificar, dividir o separar la información
		suministrada,
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>(iii) Utilizar los datos suministrados en campañas de comunicación,
		divulgación y promoción u oferta de productos, actividades o servicios
		desarrollados como parte de estrategias internas de la compañía,
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>(iv) Utilizarlos para fines administrativos internos o comerciales
		tales como: estudios de crédito, elaboración y presentación de
		cotizaciones, referencias comerciales de experiencia, investigación de
		mercados, análisis estadísticos, realización de encuestas sobre
		satisfacción, ofrecimiento o reconocimiento de beneficios propios de
		nuestro programa de lealtad y servicio postventa,
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>(v) Conservar registros históricos de la compañía y mantener contacto
		con los titulares del dato,
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>(vi) Verificar, comprobar o validar los datos suministrados,
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>(vii) Estudiar y analizar la información entregada para el seguimiento
		y mejoramiento de los productos, el servicio y la atención,
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>(viii) Entregar la información recolectada a terceros con los que la
		compañía contrate el almacenamiento y administración de los datos
		personales, bajo los estándares de seguridad y confidencialidad a los
		cuales TRANSANPABLO EXPRESS SAS está obligada según lo contenido en las
		leyes pertinentes
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>(ix) Transferir los datos personales a cualquier país o servidor en
		otro país
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>(x) Realizar el envío de información de novedades, noticias, boletines,
		foros de educación, publicidad o marketing, ventas a distancia. Haciendo
		uso de medios tales como, correo electrónico, notificaciones PUSH,
		mensajes de texto (SMS), ofertas de productos y/o servicios encontrados
		en la página web y la aplicación.
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>(xi) Comunicar y permitir el acceso a los datos personales
		suministrados a terceros proveedores de servicios de apoyo general y a
		las personas naturales o jurídicas accionistas de TRANSANPABLO EXPRESS
		SAS,</span
	>
</p>
<p class="c4">
	<span class="c1 c0"
		>(xii) Recabar, tener, manejar y utilizar la información recibida por el
		titular de la información para activar su cuenta en la aplicación,
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>(xiii) Recabar, tener, manejar y utilizar la información para realizar
		control y prevención del fraude, control y prevención de lavado de
		activos y financiación del terrorismo,</span
	>
</p>
<p class="c4">
	<span class="c1 c0"
		>(xiv) Celebración, ejecución y gestión de las propuestas de negocios y
		contratos objeto de los servicios prestados,
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>(xv) Realizar de conformidad con la ley los reportes a centrales de
		riesgo por incumplimiento de las obligaciones financieras derivadas de
		la relación comercial.</span
	>
</p>
<p class="c2 c7"><span class="c1 c3"></span></p>
<p class="c2">
	<span class="c1 c3">Derechos que le asisten al Titular: </span>
</p>
<p class="c4">
	<span class="c3">(i) </span
	><span class="c1 c0"
		>Conocer, actualizar y rectificar sus datos personales recolectados por
		TRANSANPABLO EXPRESS SAS. Este derecho se puede ejercer entre otros
		frente a datos parciales, inexactos, incompletos, fraccionados o que
		induzcan al error,
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>(ii) Solicitar prueba de la autorización otorgada a TRANSANPABLO
		EXPRESS SAS</span
	>
</p>
<p class="c4">
	<span class="c1 c0"
		>(iii)Ser informado por TRANSANPABLO EXPRESS SAS, previa solicitud,
		respecto del uso que le han dado a los datos del titular,
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>(iv) Presentar ante la Superintendencia de Industria y Comercio quejas
		por infracciones a lo dispuesto en la ley,</span
	>
</p>
<p class="c4">
	<span class="c1 c0"
		>(v) Revocar en cualquier tiempo la autorización y/o supresión del dato
		teniendo en cuenta los límites legales para ello,
	</span>
</p>
<p class="c4">
	<span class="c1 c0"
		>(vi) Acceder en forma gratuita a los datos del titular contenidos en la
		base de datos de TRANSANPABLO EXPRESS SAS.</span
	>
</p>
<p class="c2 c7"><span class="c1 c3"></span></p>
<p class="c2">
	<span class="c3">Forma de</span><span class="c0"> </span
	><span class="c3"
		>conocer la información del Titular y cómo ejercer los derechos</span
	><span class="c0"
		>: TRANSANPABLO EXPRESS SAS presenta a elección del Titular las
		siguientes formas de ejercer sus derechos de &ldquo;</span
	><span class="c0 c8">Habeas Data</span><span class="c1 c0">&rdquo;</span>
</p>
<ul class="c9 lst-kix_list_1-0 start">
	<li class="c10 li-bullet-0">
		<span class="c3">Atención electrónica</span
		><span class="c1 c0"
			>: El Titular del dato deberá realizar su requerimiento formal a la
			dirección https://www.transanpablo.com previo agotamiento de los
			requisitos de legitimación para el ejercicio del titular, a saber:
		</span>
	</li>
</ul>
<ol class="c9 lst-kix_list_2-0 start" start="1">
	<li class="c5 li-bullet-0">
		<span class="c1 c0"
			>Ser titular de la información, acreditar la identidad en forma
			suficiente mediante cualquier medio que TRANSANPABLO EXPRESS SAS
			destine para ello.</span
		>
	</li>
	<li class="c4 c11 li-bullet-0">
		<span class="c1 c0"
			>Por los causahabientes, quienes deberán acreditar tal
			calidad.</span
		>
	</li>
	<li class="c4 c11 li-bullet-0">
		<span class="c1 c0"
			>Por el representante/apoderado del titular de la información, quien
			también deberá acreditar tal calidad.</span
		>
	</li>
	<li class="c6 li-bullet-0">
		<span class="c1 c0"
			>Por estipulación a favor de otro o por otro ejercitarán por
			conjunto de las personas que estén facultadas para representarlos, e
			igualmente se deberá acreditar tal calidad.</span
		>
	</li>
</ol>
<p class="c2">
	<span class="c1 c0"
		>Los derechos de los menores de edad se ejercitarán por las personas
		encargadas de su representación, e igualmente se deberá acreditar tal
		calidad.</span
	>
</p>
<ul class="c9 lst-kix_list_3-0 start">
	<li class="c5 li-bullet-0">
		<span class="c3">Atención escrita: </span
		><span class="c0"
			>El Titular del dato deberá realizar su requerimiento formal a la
			dirección: Carrera 3 con calle 48, esquina, local 1, Barrancabermeja
			- Colombia, previo agotamiento de los requisitos de legitimación
			para el ejercicio del titular, según lo descrito en el literal </span
		><span class="c3">a</span
		><span class="c1 c0">
			del presente acápite, mediante escrito donde se especifique la
			dirección donde recibirá notificaciones y respuestas.</span
		>
	</li>
	<li class="c4 c11 li-bullet-0">
		<span class="c1 c0"
			>Atención verbal: El Titular del dato deberá realizar su
			requerimiento verbal en la dirección: Carrera 3 con calle 48,
			esquina, local 1 Barrancabermeja - Colombia, previo agotamiento de
			los requisitos de legitimación para el ejercicio del titular, según
			lo descrito en el literal a del presente acápite.</span
		>
	</li>
	<li class="c6 li-bullet-0">
		<span class="c0"
			>Atención telefónica: El Titular del dato deberá realizar su
			requerimiento a través de la línea telefónica de atención disponible
			a través de la aplicación para teléfonos móviles (aplicación </span
		><span class="c3">TRANSANPABLO EXPRESS ENTREGA</span
		><span class="c1 c0"
			>), previo agotamiento de los requisitos de legitimación para el
			ejercicio del titular, según lo descrito en el literal a del
			presente acápite.</span
		>
	</li>
</ul>
<p class="c4 c7"><span class="c1 c0"></span></p>

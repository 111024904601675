// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	firebaseConfig: {
		apiKey: 'AIzaSyBGHBvbNfoVZfGictwDWSlrEAaIZtspYXM',
		authDomain: 'mirio-progracol.firebaseapp.com',
		databaseURL: 'https://mirio-progracol.firebaseio.com',
		projectId: 'mirio-progracol',
		storageBucket: 'mirio-progracol.appspot.com',
		messagingSenderId: '770781385126',
		appId: '1:770781385126:web:fdf1d19cd8a012535fff67',
		measurementId: 'G-35Z9YV4194',
  	},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

<mat-toolbar class="toolbar">
	<button
		fxShow="false"
		fxShow.xs="true"
		mat-icon-button
		(click)="sidenav.toggle()"
	>
		<mat-icon>menu</mat-icon>
	</button>
	<span class="spacer"></span>
	<div class="left" fxShow="true" fxShow.xs="false">
		<ng-container class="nav" *ngTemplateOutlet="nav"></ng-container>
	</div>
</mat-toolbar>
<mat-sidenav-container class="fill-height content">
	<mat-sidenav
		class="side"
		fxShow="false"
		fxShow.xs="true"
		#sidenav
		mode="side"
	>
		<ng-container *ngTemplateOutlet="nav"></ng-container>
	</mat-sidenav>
	<mat-sidenav-content class="full-width fill-height">
		<main class="full-width fill-height">
			<div class="logo" routerLink="home">
				<img
					*ngIf="router.url !== '/dealer'"
					src="assets/logo.svg"
					alt=""
				/>
				<img
					*ngIf="router.url === '/dealer'"
					src="assets/logo-entregas.png"
					alt=""
				/>
			</div>
			<router-outlet class="full-width fill-height"></router-outlet>
		</main>
	</mat-sidenav-content>
</mat-sidenav-container>

<ng-template #nav>
	<mat-selection-list class="nav" [multiple]="false">
		<mat-list-option class="item" mat-ripple routerLink="home">
			inicio
		</mat-list-option>
		<mat-list-option class="item" mat-ripple routerLink="market">
			Tienda
		</mat-list-option>
		<mat-list-option class="item" mat-ripple routerLink="dealer">
			Domiciliario
		</mat-list-option>
	</mat-selection-list>
</ng-template>
